import React, {useState} from 'react';
import {TextField, Button, Typography, Container, Alert} from '@mui/material';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8081';

const ResetPassword = () => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${API_URL}/api/reset-password-request`, {email});
            setMessage('If an account exists for this email, a password reset link has been sent.');
            setError('');
        } catch (err) {
            if (err.response && err.response.status === 401 && err.response.data.auth_url) {
                // Gmail authorization required
                window.location.href = err.response.data.auth_url;
            } else {
                setError('An error occurred. Please try again.');
                setMessage('');
            }
        }
    };

    return (
        <Container maxWidth="xs">
            <Typography variant="h4" align="center" gutterBottom>
                Reset Password
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            {message && <Alert severity="success">{message}</Alert>}
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Email"
                    type="email"
                    fullWidth
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <Button type="submit" variant="contained" color="primary" fullWidth style={{marginTop: '1rem'}}>
                    Request Password Reset
                </Button>
            </form>
        </Container>
    );
};

export default ResetPassword;