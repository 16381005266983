import React from 'react';
import {Paper, Typography, Box, Button, FormControl, InputLabel, Select, MenuItem} from '@mui/material';
import axios from 'axios';

function SummarizeSection({
                              API_URL,
                              setLoading,
                              setProgress,
                              setProgressColor,
                              setStatus,
                              setCurrentUrl,
                              model,
                              setModel,
                              isCrawling,
                              isSummarizing,
                              setIsSummarizing
                          }) {
    const startSummary = async () => {
        setIsSummarizing(true);
        setLoading(true);
        setProgress(0);
        setProgressColor('primary');
        setStatus('Summarization started...\n');
        setCurrentUrl('');
        try {
            await axios.post(`${API_URL}/api/start_summary`, {model});
        } catch (error) {
            console.error('Error starting summarization:', error);
            setStatus((prevStatus) => prevStatus + `Error starting summarization: ${error.message}\n`);
            setIsSummarizing(false);
            setLoading(false);
        }
    };

    return (
        <Paper elevation={3} style={{
            padding: '1.5rem',
            height: '100%',
            minHeight: '300px',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Typography variant="h5" component="h2" gutterBottom>
                LLM Summarization
            </Typography>
            <Box style={{flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="model-select-label">Model</InputLabel>
                    <Select
                        labelId="model-select-label"
                        id="model-select"
                        value={model}
                        label="Model"
                        onChange={(e) => setModel(e.target.value)}
                        disabled={isCrawling || isSummarizing}
                    >
                        <MenuItem value="llama-3.1">LLama 3.1</MenuItem>
                        <MenuItem value="gpt-4o-mini">GPT-4o-mini</MenuItem>
                        <MenuItem value="gpt-4o">GPT-4o</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={startSummary}
                    disabled={isCrawling || isSummarizing}
                    style={{marginTop: '1.5rem'}}
                    fullWidth
                >
                    {isSummarizing ? 'Summarizing...' : 'Start Summary'}
                </Button>
            </Box>
        </Paper>
    );
}

export default SummarizeSection;