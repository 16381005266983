import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Button, Container, Grid, Paper, Typography} from '@mui/material';
import axios from 'axios';
import {io} from 'socket.io-client';
import CrawlSection from './CrawlSection';
import SummarizeSection from './SummarizeSection';
import CrawledDataSection from './CrawledDataSection';
import StatusSection from './StatusSection';
import {debounce} from 'lodash';
import {styled} from '@mui/material/styles';

function useDebounce(callback, delay) {
    const callbackRef = useRef(callback);

    // Update the ref each render
    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    const debouncedCallback = useRef(debounce((...args) => callbackRef.current(...args), delay));

    // Re-create the debounced function if delay changes
    useEffect(() => {
        const debounced = debounce((...args) => callbackRef.current(...args), delay);
        debouncedCallback.current = debounced;

        // Cleanup to cancel the debounced function on unmount or delay change
        return () => debounced.cancel();
    }, [delay]);

    return debouncedCallback.current;
}

const Dashboard = ({onLogout}) => {
    const [usage, setUsage] = useState(null);
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [currentUrl, setCurrentUrl] = useState('');
    const [progressColor, setProgressColor] = useState('primary');
    const [crawledData, setCrawledData] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [model, setModel] = useState('gpt-4o-mini');
    const [isCrawling, setIsCrawling] = useState(false);
    const [isSummarizing, setIsSummarizing] = useState(false);
    const [error, setError] = useState(null);
    const scrollPositionRef = useRef(0);

    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8081';
    const SOCKET_URL = process.env.REACT_APP_API_URL || 'http://localhost:8081';

    const GridItem = styled(Grid)(({theme}) => ({
        display: 'flex',
        marginBottom: theme.spacing(4),
        '& > *': {
            width: '100%',
        },
    }));

    useEffect(() => {
        console.log('Dashboard state updated:', {
            loading,
            progress,
            currentUrl,
            progressColor,
            isCrawling,
            isSummarizing
        });
    }, [loading, progress, currentUrl, progressColor, isCrawling, isSummarizing]);

    const socketRef = useRef(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        }
    }, []);


    const fetchUsage = useCallback(async () => {
        try {
            const response = await axios.get(`${API_URL}/api/usage`);
            console.log('Usage data received:', response.data);
            setUsage(response.data);
        } catch (error) {
            console.error('Error fetching usage:', error);
            setError('Failed to fetch usage data. Please try logging in again.');
            if (error.response && error.response.status === 401) {
                // Handle unauthorized access (e.g., redirect to login)
                onLogout();
            }
        }
    }, [API_URL, onLogout]);

    const fetchCrawledData = useCallback(async (pageNumber) => {
        console.log('fetchCrawledData called with page:', pageNumber);
        try {
            const response = await axios.get(`${API_URL}/api/crawled_data?page=${pageNumber}&limit=5`);
            console.log('Crawled data response:', response.data);
            setCrawledData(response.data.items);

            const totalItems = Math.max(response.data.total, (pageNumber - 1) * 5 + response.data.items.length);
            setTotalPages(Math.ceil(totalItems / 5));
        } catch (error) {
            console.error('Error fetching crawled data:', error);
            setStatus((prevStatus) => prevStatus + `Error fetching crawled data: ${error.message}\n`);
        }
    }, [API_URL, setStatus]);

    useLayoutEffect(() => {
        if (page > 1) {
            window.scrollTo(0, scrollPositionRef.current);
        }
    }, [page]);

    const handlePageChange = useCallback((event, newPage) => {
        event.preventDefault();
        scrollPositionRef.current = window.pageYOffset;
        setPage(newPage);
    }, []);

    const updateCrawledData = useCallback((updatedData) => {
        setCrawledData(updatedData);
    }, []);

    const deleteCrawledData = useCallback(async (id) => {
        try {
            const response = await axios.delete(`${API_URL}/api/crawled_data/${id}`);
            if (response.data && response.data.updatedData) {
                setCrawledData(response.data.updatedData.items);
                setTotalPages(Math.ceil(response.data.updatedData.total / 5));
            } else {
                // If the API doesn't return updated data, fetch it
                await fetchCrawledData(1);
            }
            setStatus((prevStatus) => prevStatus + `Deleted entry: ${id}\n`);
            return response;
        } catch (error) {
            console.error('Error deleting crawled data:', error);
            setStatus((prevStatus) => prevStatus + `Error deleting entry: ${error.message}\n`);
            throw error;
        }
    }, [API_URL, fetchCrawledData, setStatus]);

    const debouncedFetchCrawledData = useDebounce(fetchCrawledData, 300);

    useEffect(() => {
        fetchUsage();
        // debouncedFetchCrawledData(1);

        const token = localStorage.getItem('token');
        console.log('Token for socket connection:', token ? 'exists' : 'not found');

        if (!token) {
            console.error('No token found for socket connection');
            onLogout(); // Redirect to login if no token is found
            return;
        }

        socketRef.current = io(SOCKET_URL, {
            transports: ['websocket'],
            auth: {token},
            query: {token} // Some implementations might require the token in the query
        });

        socketRef.current.on('connect', () => {
            console.log('Socket connected successfully. Socket ID:', socketRef.current.id);
        });

        socketRef.current.on('connect_error', (error) => {
            console.error('Socket connection error:', error);
            if (error.message === 'jwt expired') {
                onLogout(); // Redirect to login if token is expired
            }
        });

        socketRef.current.onAny((eventName, ...args) => {
            console.log(`Received event in Dashboard: ${eventName}`, JSON.stringify(args));
        });

        socketRef.current.on('disconnect', (reason) => {
            console.log('Socket disconnected:', reason);
        });

        socketRef.current.on('status', (data) => {
            console.log('Status update:', data);
            setStatus(prevStatus => prevStatus + data.message + '\n');
        });

        socketRef.current.on('progress', (data) => {
            console.log('Progress event received:', data);
            setCurrentUrl(data.url);
            setProgress(data.progress);
            setStatus(prevStatus => prevStatus + `Processing ${data.url}: ${Math.round(data.progress)}% in ${data.processing_time} seconds.\n`);
            if (data.progress === 100) {
                setProgressColor('success');
            } else {
                setProgressColor('primary');
            }
        });

        socketRef.current.on('crawl_finished', (data) => {
            console.log('Crawl finished:', data);
            setStatus(prevStatus => prevStatus + `Crawl finished. Pages crawled: ${data.pages_crawled}\n`);
            setIsCrawling(false);
            setLoading(false);
            setProgress(100);
            setProgressColor('success');
            fetchCrawledData(1);
            fetchUsage();
        });

        socketRef.current.on('summary_finished', (data) => {
            console.log('Summary finished event received in Dashboard:', data);
            setStatus(prevStatus => prevStatus + 'Summary finished\n');
            setIsSummarizing(false);
            setLoading(false);
            setProgress(100);
            setProgressColor('success');
            fetchCrawledData(1);
            fetchUsage();
        });

        return () => {
            if (socketRef.current) {
                socketRef.current.disconnect();
            }
        };
    }, [debouncedFetchCrawledData, fetchUsage, fetchCrawledData, onLogout, SOCKET_URL]);

    // Add this new useEffect to fetch data when page changes
    useEffect(() => {
        if (page > 0) {
            fetchCrawledData(page);
        }
    }, [page, fetchCrawledData]);

    if (error) {
        return (
            <Container>
                <Typography variant="h6" color="error">{error}</Typography>
                <Button onClick={onLogout} variant="contained" color="primary">
                    Log out
                </Button>
            </Container>
        );
    }

    return (
        <Container maxWidth="xl" style={{marginTop: '2rem', marginBottom: '2rem'}}>
            <Grid container justifyContent="space-between" alignItems="center" marginBottom={4}>
                <Grid item>
                    <Typography variant="h3" component="h1">
                        Web Crawler Dashboard
                    </Typography>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="secondary" onClick={onLogout}>
                        Logout
                    </Button>
                </Grid>
            </Grid>

            {usage && (
                <Paper elevation={3} style={{padding: '1rem', marginBottom: '2rem'}}>
                    <Typography variant="h6">Usage Limits</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Typography>
                                Crawl: {usage.usage?.crawl_count || 0} / {usage.limits?.daily_crawl_limit || 'Not set'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography>
                                Summarize: {usage.usage?.summarize_count || 0} / {usage.limits?.daily_summarize_limit || 'Not set'}
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
            )}

            <Grid container spacing={4}>
                <GridItem item xs={12} md={6}>
                    <CrawlSection
                        API_URL={API_URL}
                        setLoading={setLoading}
                        setProgress={setProgress}
                        setProgressColor={setProgressColor}
                        setStatus={setStatus}
                        isCrawling={isCrawling}
                        setIsCrawling={setIsCrawling}
                        isSummarizing={isSummarizing}
                    />
                </GridItem>
                <GridItem item xs={12} md={6}>
                    <SummarizeSection
                        API_URL={API_URL}
                        setLoading={setLoading}
                        setProgress={setProgress}
                        setProgressColor={setProgressColor}
                        setStatus={setStatus}
                        setCurrentUrl={setCurrentUrl}
                        model={model}
                        setModel={setModel}
                        isCrawling={isCrawling}
                        isSummarizing={isSummarizing}
                        setIsSummarizing={setIsSummarizing}
                    />
                </GridItem>
                <GridItem item xs={12} md={6}>
                    <CrawledDataSection
                        crawledData={crawledData}
                        updateCrawledData={updateCrawledData}
                        deleteCrawledData={deleteCrawledData}
                        page={page}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        fetchCrawledData={fetchCrawledData}
                        setStatus={setStatus}
                    />
                </GridItem>
                <GridItem item xs={12} md={6}>
                    <StatusSection
                        loading={loading}
                        currentUrl={currentUrl}
                        status={status}
                        progress={progress}
                        progressColor={progressColor}
                    />
                </GridItem>
            </Grid>
        </Container>
    );
};

export default Dashboard;