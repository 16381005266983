import React, {useCallback, useState} from 'react';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    IconButton,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    Alert,
    Snackbar,
    Pagination,
    Link
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import ExpandableContent from "./ExpandableContent";
import ReactMarkdown from 'react-markdown';

function CrawledDataSection({
                                crawledData,
                                updateCrawledData,
                                deleteCrawledData,
                                fetchCrawledData,
                                page,
                                totalPages,
                                onPageChange
                            }) {
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [downloadItem, setDownloadItem] = useState(null);
    const [downloadModalOpen, setDownloadModalOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({open: false, message: '', severity: 'success'});

    console.log('CrawledDataSection rendered with data:', crawledData);

    const handleDelete = async () => {
        if (!deleteItemId) return;

        setDeleteConfirmOpen(false);

        try {
            const response = await deleteCrawledData(deleteItemId);
            if (response && response.data && response.data.updatedData) {
                updateCrawledData(response.data.updatedData.items);
            } else {
                // If the API doesn't return updated data, fetch it
                await fetchCrawledData(page);
            }
            setSnackbar({open: true, message: 'Item deleted successfully', severity: 'success'});
        } catch (error) {
            console.error('Error deleting item:', error);
            setSnackbar({open: true, message: 'Error deleting item', severity: 'error'});
        }

        setDeleteItemId(null);
    };

    const openDeleteConfirm = (id) => {
        setDeleteItemId(id);
        setDeleteConfirmOpen(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar({...snackbar, open: false});
    };

    const handleDownload = (format) => {
        if (!downloadItem) return;

        let content, filename, type;

        if (format === 'json') {
            content = JSON.stringify(downloadItem, null, 2);
            type = 'application/json';
            filename = `${downloadItem.title.slice(0, 20)}.json`;
        } else {
            content = downloadItem.content;
            type = 'text/plain';
            filename = `${downloadItem.title.slice(0, 20)}.txt`;
        }

        const blob = new Blob([content], {type});
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setDownloadModalOpen(false);
    };

    const handlePageChange = useCallback((event, newPage) => {
        event.preventDefault();
        onPageChange(event, newPage);
    }, [onPageChange]);

    return (<Paper elevation={3} style={{
        padding: '1.5rem',
        height: '100%',
        minHeight: '300px',
        display: 'flex',
        flexDirection: 'column'
    }}>
        <Typography variant="h4" gutterBottom>Crawled Data</Typography>
        {crawledData.length === 0 ? (
            <Typography>No data available</Typography>
        ) : (
            <>
                {crawledData.map((item) => (
                    <Accordion key={item._id}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography>{item.title || 'No Title'}</Typography>
                            <Box ml="auto">
                                <IconButton onClick={(e) => {
                                    e.stopPropagation();
                                    setDownloadItem(item);
                                    setDownloadModalOpen(true);
                                }}>
                                    <DownloadIcon/>
                                </IconButton>
                                <IconButton onClick={(e) => {
                                    e.stopPropagation();
                                    openDeleteConfirm(item._id);
                                }}>
                                    <DeleteIcon/>
                                </IconButton>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                <strong>URL:</strong>{' '}
                                <Link href={item.url} target="_blank" rel="noopener noreferrer">
                                    {item.url}
                                </Link>
                            </Typography>
                            <Typography><strong>Crawled At:</strong> {new Date(item.crawled_at * 1000).toLocaleString()}
                            </Typography>
                            <ExpandableContent label="Content">
                                {item.content}
                            </ExpandableContent>
                            <ExpandableContent label="LLM Extraction">
                                <ReactMarkdown>{item.llm_extraction}</ReactMarkdown>
                            </ExpandableContent>
                        </AccordionDetails>
                    </Accordion>
                ))}
                <Box display="flex" justifyContent="center" mt={2}>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </Box>
            </>
        )}

        {/* Delete Confirmation Dialog */}
        <Dialog
            open={deleteConfirmOpen}
            onClose={() => setDeleteConfirmOpen(false)}
        >
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete this item?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setDeleteConfirmOpen(false)}>Cancel</Button>
                <Button onClick={handleDelete} color="error">Delete</Button>
            </DialogActions>
        </Dialog>

        {/* Download Options Dialog */}
        <Dialog
            open={downloadModalOpen}
            onClose={() => setDownloadModalOpen(false)}
        >
            <DialogTitle>Download Options</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Choose the format to download the crawled data:
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleDownload('json')}>JSON</Button>
                <Button onClick={() => handleDownload('txt')}>TXT</Button>
                <Button onClick={() => setDownloadModalOpen(false)}>Cancel</Button>
            </DialogActions>
        </Dialog>

        <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
            <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{width: '100%'}}>
                {snackbar.message}
            </Alert>
        </Snackbar>
    </Paper>);
}

export default CrawledDataSection;