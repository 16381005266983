import React, {useState} from 'react';
import {Typography, Button, Box} from '@mui/material';

const ExpandableContent = ({label, children, previewLength = 300}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleExpansion = () => setIsExpanded(!isExpanded);

    let content, stringContent;

    if (typeof children === 'string') {
        stringContent = children;
        content = <Typography>{children}</Typography>;
    } else if (React.isValidElement(children)) {
        content = children;
        stringContent = children.props.children;
    } else {
        return null; // Invalid content type
    }

    if (!stringContent) return null;

    const contentPreview = stringContent.slice(0, previewLength);
    const hasMoreContent = stringContent.length > previewLength;

    const renderContent = () => {
        if (typeof children === 'string') {
            return <Typography>{isExpanded ? stringContent : contentPreview}</Typography>;
        } else {
            return React.cloneElement(content, {children: isExpanded ? stringContent : contentPreview});
        }
    };

    return (
        <Box mt={2}>
            <Typography variant="subtitle1"><strong>{label}:</strong></Typography>
            {renderContent()}
            {hasMoreContent && !isExpanded && '...'}
            {hasMoreContent && (
                <Button onClick={toggleExpansion}>
                    {isExpanded ? 'Show Less' : 'Show More'}
                </Button>
            )}
        </Box>
    );
};

export default ExpandableContent;