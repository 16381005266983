import React, {useEffect, useRef} from 'react';
import {Paper, Typography, Box, CircularProgress, LinearProgress} from '@mui/material';

function StatusSection({loading, currentUrl, status, progress, progressColor}) {
    const statusRef = useRef(null);

    useEffect(() => {
        if (statusRef.current) {
            statusRef.current.scrollTop = statusRef.current.scrollHeight;
        }
    }, [status]);

    return (
        <Paper elevation={3} style={{padding: '1.5rem', height: '600px', display: 'flex', flexDirection: 'column'}}>
            <Typography variant="h5" component="h2" gutterBottom>
                Status
            </Typography>
            <Box mb={2} display="flex" alignItems="center">
                {loading && (
                    <>
                        <CircularProgress size={24} style={{marginRight: '1rem'}}/>
                        <Typography variant="body1" component="p">
                            {currentUrl ? `Processing: ${currentUrl}` : 'Processing...'}
                        </Typography>
                    </>
                )}
            </Box>
            <textarea
                ref={statusRef}
                value={status}
                readOnly
                style={{width: '100%', flexGrow: 1, marginBottom: '1rem', resize: 'none'}}
            />
            {(loading || progress < 100) && (
                <Box mt={2}>
                    <LinearProgress variant="determinate" value={progress} color={progressColor}/>
                    <Typography variant="body2" color="textSecondary">{`${Math.round(progress)}%`}</Typography>
                </Box>
            )}
        </Paper>
    );
}

export default StatusSection;