import React, {useEffect, useState} from 'react';
import {TextField, Button, Typography, Container, Link, Alert} from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';
import GitHubIcon from '@mui/icons-material/GitHub';
import GoogleIcon from '@mui/icons-material/Google';

const API_URL = process.env.REACT_APP_API_URL;

const Login = ({onLogin}) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const msg = params.get('message');
        const err = params.get('error');

        if (msg === 'verification_success') {
            setMessage('Email verified successfully. Please log in.');
        } else if (msg === 'already_verified') {
            setMessage('Email already verified. Please log in.');
        }

        if (err === 'invalid_token') {
            setError('Invalid or expired verification link.');
        } else if (err === 'user_not_found') {
            setError('User not found. Please register.');
        } else if (err === 'verification_failed') {
            setError('Verification failed. Please try again or contact support.');
        } else if (err === 'email_exists_non_github') {
            setError('This email is already registered without GitHub. Please log in with your email and password.');
        }
    }, [location]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_URL}/api/login`, {email, password});
            const {access_token} = response.data;
            localStorage.setItem('token', access_token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
            onLogin();
            navigate('/');
        } catch (err) {
            setError('Invalid email or password');
        }
    };

    const handleGitHubLogin = () => {
        window.location.href = `${API_URL}/api/login/github`;
    };

    const handleGoogleLogin = useGoogleLogin({
        onSuccess: async (tokenResponse) => {
            console.log('Google login response:', tokenResponse);
            try {
                // Get user info using the access token
                const userInfoResponse = await axios.get(
                    'https://www.googleapis.com/oauth2/v3/userinfo',
                    { headers: { Authorization: `Bearer ${tokenResponse.access_token}` } }
                );
                console.log('User info:', userInfoResponse.data);

                // Send the user info to your backend
                const res = await axios.post(`${API_URL}/api/login/google`, {
                    userInfo: userInfoResponse.data
                });
                console.log('Backend response:', res.data);
                const { access_token } = res.data;
                localStorage.setItem('token', access_token);
                axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
                onLogin();
                navigate('/');
            } catch (error) {
                console.error('Error logging in with Google:', error);
                if (error.response) {
                    console.error('Error response:', error.response.data);
                    setError(`Error logging in with Google: ${error.response.data.error || 'Unknown error'}`);
                } else if (error.request) {
                    console.error('Error request:', error.request);
                    setError('Error logging in with Google: No response received from server');
                } else {
                    console.error('Error message:', error.message);
                    setError(`Error logging in with Google: ${error.message}`);
                }
            }
        },
        onError: (error) => {
            console.error('Google login error:', error);
            setError(`Google login failed: ${error}`);
        },
    });

    return (
        <Container maxWidth="xs">
            <Typography variant="h4" align="center" gutterBottom>
                Login
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            {message && <Alert severity="success">{message}</Alert>}
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Email"
                    type="email"
                    fullWidth
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <TextField
                    label="Password"
                    type="password"
                    fullWidth
                    margin="normal"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <Button type="submit" variant="contained" color="primary" fullWidth style={{marginTop: '1rem'}}>
                    Login
                </Button>
            </form>
            <Button
                onClick={handleGitHubLogin}
                variant="contained"
                color="secondary"
                fullWidth
                style={{marginTop: '1rem'}}
                startIcon={<GitHubIcon />}
            >
                Login with GitHub
            </Button>
            <Button
                onClick={() => handleGoogleLogin()}
                variant="contained"
                color="primary"
                fullWidth
                style={{marginTop: '1rem'}}
                startIcon={<GoogleIcon />}
            >
                Sign in with Google
            </Button>
            <Typography align="center" style={{marginTop: '1rem'}}>
                <Link href="/signup">Don't have an account? Sign up</Link>
            </Typography>
            <Typography align="center">
                <Link href="/reset-password">Forgot password?</Link>
            </Typography>
        </Container>
    );
};

export default Login;