import React, {useState} from 'react';
import {Paper, Typography, Box, TextField, Button} from '@mui/material';
import axios from 'axios';

function CrawlSection({
                          API_URL,
                          setLoading,
                          setProgress,
                          setProgressColor,
                          setStatus,
                          isCrawling,
                          setIsCrawling,
                          isSummarizing
                      }) {
    const [startUrl, setStartUrl] = useState('');
    const [maxDepth, setMaxDepth] = useState(1);
    const [maxPages, setMaxPages] = useState(10);

    const startCrawl = async () => {
        setIsCrawling(true);
        setLoading(true);
        setProgress(0);
        setProgressColor('primary');
        setStatus('Crawling started...\n');
        try {
            const response = await axios.post(`${API_URL}/api/start_crawl`, {
                start_url: startUrl,
                max_depth: maxDepth,
                max_pages: maxPages,
            });

            setStatus((prevStatus) => prevStatus + `Crawl started for: ${response.data.start_url}\n`);
        } catch (error) {
            console.error('Error starting crawl:', error);
            setStatus((prevStatus) => prevStatus + `Error starting crawl: ${error.message}\n`);
            setIsCrawling(false);
            setLoading(false);
        }
    };

    return (
        <Paper elevation={3} style={{
            padding: '1.5rem',
            height: '100%',
            minHeight: '300px',
            display: 'flex',
            flexDirection: 'column'
        }}>
            <Typography variant="h5" component="h2" gutterBottom>
                Crawling
            </Typography>
            <Box component="form" noValidate autoComplete="off"
                 style={{flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                <div>
                    <TextField
                        label="Start URL"
                        fullWidth
                        margin="normal"
                        value={startUrl}
                        onChange={(e) => setStartUrl(e.target.value)}
                        disabled={isCrawling || isSummarizing}
                    />
                    <TextField
                        label="Max Depth"
                        type="number"
                        fullWidth
                        margin="normal"
                        value={maxDepth}
                        onChange={(e) => setMaxDepth(Number(e.target.value))}
                        disabled={isCrawling || isSummarizing}
                    />
                    <TextField
                        label="Max Pages"
                        type="number"
                        fullWidth
                        margin="normal"
                        value={maxPages}
                        onChange={(e) => setMaxPages(Number(e.target.value))}
                        disabled={isCrawling || isSummarizing}
                    />
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={startCrawl}
                    disabled={isCrawling || isSummarizing}
                    style={{marginTop: '1.5rem'}}
                    fullWidth
                >
                    {isCrawling ? 'Crawling...' : 'Start Crawl'}
                </Button>
            </Box>
        </Paper>
    );
}

export default CrawlSection;